<template>
  <b-modal id="collectAccountInfoModal"
           ref="collectAccountInfoModal"
           title="Verify Contact Information"
           size="xl"
           hide-footer
           hide-header-close
  >
    <b-container v-if="!busy">
      <validation-observer v-slot="{ invalid }">
        <b-form @submit.prevent="onSubmit">
          <b-form-group label="General">
            <b-row>
              <b-col>
                <validation-provider v-slot="{ errors }" rules="required" name="First Name">
                  <b-form-input
                    v-model="form.firstName"
                    required
                    placeholder="First Name"
                  />
                  <span>
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="{ errors }" rules="required" name="Last Name">
                  <b-form-input v-model="form.lastName" required placeholder="Last Name" />
                  <span>
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group label="Contact Info">
            <b-row>
              <b-col>
                <validation-provider v-slot="{ errors }" rules="required" name="Phone">
                  <b-form-input v-model="form.phoneNumber" required placeholder="Phone" />
                  <span>
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Mailing Address">
            <b-row>
              <b-col>
                <validation-provider v-slot="{ errors }" rules="required" name="Address">
                  <b-form-input v-model="form.line1" required placeholder="Address" />
                  <span>
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col>
                <b-form-input v-model="form.line2" placeholder="Apt, Suite, Building - Optional" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider v-slot="{ errors }" rules="required" name="City">
                  <b-form-input v-model="form.city" required placeholder="City" />
                  <span>
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider v-slot="{ errors }" rules="required" name="State">
                  <b-form-select
                    v-model="form.stateProvinceRegion"
                    required
                    :options="usStates"
                  />
                  <span>
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider v-slot="{ errors }" rules="required" name="Zipcode">
                  <b-form-input v-model="form.zipPostalCode" required placeholder="Zipcode" />
                  <span>
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
            </b-row>
            <validation-provider v-slot="{ errors }" rules="required" name="Country">
              <b-form-select v-model="form.country" required data-cy="country">
                <option v-for="option in countries" :key="option.value" :value="option.value">
                  <label :data-cy="`select-option-${option.value}`">
                    {{ option.text }}
                  </label>
                </option>
              </b-form-select>
              <span>
                {{ errors[0] }}
              </span>
            </validation-provider>
          </b-form-group>
          <b-form-group class="float-right">
            <b-button variant="danger" class="mr-1" @click="reload()">
              Cancel
            </b-button>
            <b-button type="submit" :disabled="invalid" variant="primary">
              Save
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-container>
    <ct-centered-spinner v-else />
  </b-modal>
</template>

<script>
import http from '@/http'
import { usStates } from '@/common/modules/usStates'
import { countries } from '@/common/modules/countries'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: 'CollectAccountInfo',
  components: { ValidationProvider, ValidationObserver, CtCenteredSpinner },

  data() {
      return {
        busy: false,
        form: {
            personId: null,
            personPhoneId: null,
            personAddressesId: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            line1: null,
            line2: null,
            city: null,
            stateProvinceRegion: null,
            country: 'US',
            zipPostalCode: null,
        },
      }
  },
  computed: {
    ...mapGetters('account', ['account']),
    usStates() {
      const states = usStates.map((et) => [{
        text: et.stateProvinceRegion,
        value: et.abbreviation,
      }]).flat()
      return [{ value: null, text: 'Please select a state' }, ...states]
    },
    countries() {
      return [{ value: null, text: 'Please select a country' }, ...countries]
    },
  },
  async mounted() {
    await this.refresh()
  },
  methods: {
    ...mapActions('account', ['loadAccount']),
    async onSubmit(_evt) {
      this.busy = true
      try {
        const results = []
        const personId = this.form.personId

        const personParams = {
          person: {
            id: personId,
            first_name: this.form.firstName,
            last_name: this.form.lastName,
          },
        }
        results.push(await http.put(`/client/person/${personId}`, personParams))

        const existingPhone = this.form.personPhoneId != null
        const phoneParams = { phone: { phone_number: this.form.phoneNumber, person_id: personId, kind: 'primary' } }
        const phonePath = existingPhone ? `/client/person_phones/${this.form.personPhoneId}` : '/client/person_phones'

        results.push(await http[existingPhone ? 'put' : 'post'](phonePath, phoneParams))

        const existingAddress = this.form.personAddressesId != null
        const addressPath = existingAddress ? `/client/person_addresses/${this.form.personAddressesId}` : '/client/person_addresses'
        const addressParams = {
          address: {
            person_id: this.form.personId,
            line1: this.form.line1,
            line2: this.form.line2,
            city: this.form.city,
            state_province_region: this.form.stateProvinceRegion,
            country: this.form.country,
            zip_postal_code: this.form.zipPostalCode,
            kind: 'primary',
          },
        }
        results.push(await http[existingAddress ? 'put' : 'post'](addressPath, addressParams))
        const success = results.filter((a) => !a.data.success).length === 0

        this.makeToast(`Information ${success ? 'saved' : 'not saved'}`, false, success ? 'success' : 'danger')
        if (success){
          this.$refs['collectAccountInfoModal'].hide()
        } else {
          this.$refs['collectAccountInfoModal'].show()
        }
        if (success){
          await this.loadAccount()
          await this.$emit('success')
        }
        this.busy = false
      } catch {
        this.makeToast(`Information not saved. Make sure the information is valid.`, false, 'danger')
        this.busy = false
      }
    },
    async reload(){
      if ([
        'stageline-checkout-payment',
        'stageline-v2-checkout-payment',
      ].includes(this.$route.name)) {
        // Probly should do this for all areas this is used instead of being stupid and just reloading
        // the entire fucking page. but i'm too tired and worn out to test all of that, so here is the
        // better way for at least stageline
        await this.loadAccount()
        this.$refs['collectAccountInfoModal'].hide()
      } else {
        window.location.reload()
      }
      this.busy = false
    },
    async show(){
      this.$refs['collectAccountInfoModal'].show()
    },
    async refresh(){
      await this.loadAccount()

      if(!this.account){
        this.makeToast('Missing person')
        return
      }

      const person = this.account
      this.form.personId  = person.id
      this.form.firstName = person.first_name
      this.form.lastName  = person.last_name

      if (person.person_phones?.length > 0) {
        const phone = person.person_phones.filter(pp => pp.kind === 'primary').length > 0 ? person.person_phones.filter(pp => pp.kind === 'primary')[0] : person.person_phones[0]
        this.form.phoneNumber = phone.phone_number
        this.form.personPhoneId = phone.id
      }

      if (person.person_addresses?.length > 0) {
        const address = person.person_addresses.filter(pa => pa.kind === 'primary').length > 0 ? person.person_addresses.filter(pa => pa.kind === 'primary')[0] : person.person_addresses[0]
        this.form.personAddressesId = address.id
        this.form.line1 = address.line1
        this.form.line2 = address.line2
        this.form.city = address.city
        this.form.stateProvinceRegion = _.startCase(address.state_province_region)
        this.form.country = _.startCase(address.country)
        this.form.zipPostalCode = address.zip_postal_code
      }

    },
    makeToast(msg = '', append = false, variant = 'danger') {
      this.$bvToast.toast(msg, {
        autoHideDelay: 5000,
        appendToast: append,
        variant,
      })
    },
  },
}
</script>

<style lang="scss">
  .row {
    margin-bottom: 1em
  }
</style>
